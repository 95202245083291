import { render, staticRenderFns } from "./AtendimentoPage.vue?vue&type=template&id=7f451678&scoped=true"
import script from "./AtendimentoPage.vue?vue&type=script&lang=js"
export * from "./AtendimentoPage.vue?vue&type=script&lang=js"
import style0 from "./AtendimentoPage.vue?vue&type=style&index=0&id=7f451678&prod&scoped=true&lang=css"
import style1 from "./AtendimentoPage.vue?vue&type=style&index=1&id=7f451678&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f451678",
  null
  
)

export default component.exports